@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import 'colors';

footer {
    font-size: 16px;
    background-color: $footer-bg;
    padding: 10px 0 0 0 !important;
    color: $footer-color;
    z-index: 50;
    position: relative;

    a {
        color: $footer-color;
        text-decoration: none;

        &:hover {
            color: $footer-a-active;
        }
    }
}

@include media-breakpoint-down(md) {
    footer {
        .footer-address {
            text-align: center;
        }

        .footer-copyright {
            text-align: center;
        }
    }
}

@include media-breakpoint-up(md) {
    footer {
        .footer-copyright {
            text-align: right;
        }
    }
}
