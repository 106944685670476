.help-slide-panel {
    z-index: 99;

    h1 {
        font-size: 29px !important;
    }

    .article-header {
        .fa {
            position: absolute;
            cursor: pointer;
        }

        h1 {
            padding-left: 30px;
            font-size: 24px;
        }
    }

    .contact-form-header {
        .fa {
            position: absolute;
            cursor: pointer;
        }

        h1 {
            padding-left: 30px;
            font-size: 24px;
        }
    }
}

.slide-pane__overlay {
    position: fixed;
    top: 0;
    left: unset;
    right: 0;
    bottom: 0;
    width: 450px;
}

.slide-pane__subtitle {
    font-size: 0.65em;
    margin-top: -7px;
}

.slide-pane {
    .slide-pane__title {
        font-size: 1em;
    }
}
