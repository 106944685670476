@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import 'colors';

#menuLeft, #menuLeftInner {
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

#menuLeft {
    height: calc(100vh - 60px);
    width: 247px;
    background-color: $primary;
    padding-top: 30px;
    padding-bottom: 4.5em;
    position: fixed;
    top: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 5;

    #menuLeftInner {
        transition: 0.5s;
        max-width: 90vh;

        a {
            text-decoration: none;
        }

        .left-user-summary {
            text-align: center;
            color: $light;
            font-size: 14px;

            a {
                color: $link-blue;
                font-weight: 600;
            }

            .left-avatar {
                width: 86px;
                border-radius: 65px;
            }

            .left-name {
                height: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.86;
                letter-spacing: normal;
                text-align: center;
                font-family: 'Open Sans', sans-serif !important;
            }

            .left-team {
                height: 20px;
                font-family: 'Open Sans', sans-serif !important;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.86;
                letter-spacing: normal;
                text-align: center;
                color: $light;
            }
        }

        .left-menu-items {
            margin-top: 32px;
            display: grid;
            overflow: hidden;

            .navbar-brand {
                color: $light;
                font-size: 14px;
                width: 100%;
                height: 100%;
                margin-right: 0;
                margin-left: 16px;
                padding: 0 0 0 15px;

                &:hover {
                    color: $live-hover-green !important;
                }

                &:focus {
                    color: $light;
                }

                img {
                    width: 18px;
                    height: 16px;
                    margin-right: 30px;
                }
            }

            .navbar {
                height: 73px;
                margin: 0;
                border-bottom: 1px solid $navbar-highlight;
                background-color: $primary;
                cursor: pointer;

                &:first-child {
                    border-top: 1px solid $navbar-highlight;
                }

                .activeAccentLeftBox {
                    display: none;
                    width: 8px;
                    height: 72px;
                    background-color: $navbar-highlight;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                &.selected {
                    height: 73px;
                    margin: 0;
                    box-shadow: inset 0 1px 3px 0 $navbar-shadow;
                    background-color: rgba(20, 68, 100, 0.5);
                    border: none;
                    border-bottom: 1px solid $navbar-highlight;

                    .activeAccentLeftBox {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 450px) {
    #menuLeft {
        padding-top: 15px;
    }

    #menuLeft a {
        font-size: 18px;
    }
}

@media screen and (max-width: 767px) {
    #menuLeft {
        position: fixed !important;
        z-index: 99;
    }
    #userIconLeft {
        display: none !important;
    }
}
