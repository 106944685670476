// Bootstrap primary colors
$primary: #20587f; // Blumine
$secondary: #babebe; // Silver Sand
$info: #7db6d4; // Half Baked
$warning: #ffcd6f; // Goldenrod
$success: #26c9b4; // Java
//$success: #008201; // Japanese Laurel
$danger: #e32636; // Alizarin crimson
$light: #ffffff; // White
$dark: #03020e; // Black Pearl

// Bootstrap Modal Color Overrides
$btn-close-focus-shadow: #babebe;

// Navbar
$navbar-highlight: rgb(14, 216, 174);
$navbar-shadow: rgba(0, 0, 0, 0.31);
$navbar-border-bottom: #0ed8ae;

// Header Search Results
$top-search-results: #373737;

// Links
$link: white;
$link-blue: #2faeea;
$live-hover: #5bbaea;
$live-hover-green: #0ed8ae;
