@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import 'colors';

.top-signin-landing {
    display: none !important;
}

.iconAlertBell {
    .fa-bell {
        font-size: 27px;
    }
}

.has-search {
    display: flex;
    align-items: center;
    margin-right: 50px !important;

    .form-control {
        padding-left: 2.875rem;
    }

    .form-control-search {
        position: absolute;
        z-index: 2;
        display: block;
        width: 1.2rem;
        height: 2.375rem;
        margin: 0 15px;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: $input-bg;
    }

    svg {
        height: 30px !important;
    }

    input {
        width: 371px;
        border-radius: 22.5px;
        -webkit-box-shadow: inset 0 1px 3px 0 $input-box-shadow;
        box-shadow: inset 0 1px 3px 0 $input-box-shadow;
        background-color: $input-bg;
        border: solid 1px;
        font-size: 16px;
    }
}

.react-autosuggest__input {
    color: $react-autosuggest-input;
    padding: 5px 3px 5px 45px;
}

#react-autowhatever-header-search-results {
    width: 371px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 $input-box-shadow;
    background-color: $input-bg;
    position: absolute;
    text-align: left;
    padding: 0;
    color: $dark;
    font-size: 12px;
    display: none;

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        color: $top-search-results;
        border-bottom: solid 1px $dark;
        padding: 0;

        &:last-child {
            border-bottom: none;
        }

        .search-result-link {
            color: $input-box-shadow !important;
            font-size: 12px !important;
        }
    }
}

.react-autosuggest__suggestion {
    > div {
        padding: 7px 15px;

        &:hover {
            background: $live-hover;
            cursor: pointer;
        }
    }

    .search-result-link {
        text-transform: none !important;
    }
}

.react-autosuggest__suggestions-container--open {
    display: block !important;
}

.top-line {
    .top-signout {
        position: absolute;
        right: 0;
        top: -5px;
    }
}

.top-line .top-signout-mobile, .top-line .top-signin-mobile {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}

@include media-breakpoint-down(sm) {
    .top-search-find-wrapper {
        position: absolute;
        right: 20px;
        top: 0;
    }

    .has-search {
        input {
            width: 200px;
        }
    }
}

@include media-breakpoint-down(md) {
    #topbarNav {
        margin-right: -45px !important;
        margin-top: 35px;
        position: absolute;
        right: -30px;
        top: 10px;
        padding: 20px 20px 20px 100px;
        width: calc(100vw - 50px);
    }
    .has-search {
        margin: 0 auto !important;
        width: 90vw;

        input {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .top-line .top-signout-mobile, .top-line .top-signin-mobile {
        top: 0.45em;
    }
}

@include media-breakpoint-up(lg) {
    .top-line .top-signout-mobile, .top-line .top-signin-mobile {
        right: 0;
        top: 0;
        display: none !important;
    }
}
