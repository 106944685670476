@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import 'Assets/scss/colors';

//// Bootstrap and its default variables
//$grid-breakpoints: (
//    xs: 0,
//    sm: 576px,
//    md: 768px,
//    lg: 992px,
//    xl: 1200px,
//    xxl: 1400px
//);
@import "../node_modules/bootstrap/scss/bootstrap";

// Bootstrap Modal
.modal-dialog .close {
    @extend .btn-close;

    span {
        visibility: hidden;
    }

    :focus, :active {
        box-shadow: none;
    }
}

// Navbars
.nav-tabs .nav-item.active, .nav-tabs .nav-item.active {
    color: $dark !important;
    font-weight: bold;
    border-bottom: 4px solid $navbar-border-bottom !important;
}

.nav-tabs .nav-link {
    border: none !important;
}

.nav-item:hover {
    color: #0ed8ae !important;
}

// Bootstrap Buttons
input {
    &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;

        &:hover {
            transition: background-color 5000s ease-in-out 0s;
        }

        &:focus {
            transition: background-color 5000s ease-in-out 0s;
        }

        &:active {
            transition: background-color 5000s ease-in-out 0s;
        }
    }
}

.btn {
    min-width: 117px;
    border-radius: 5px !important;
    font-size: 14px !important;
}

.btn-outline-white {
    color: #cecece !important;
    border-color: #cecece !important;
}

.btn-outline-white:hover, .btn-outline-white:active, .btn-outline-white:focus {
    color: #fff !important;
    background-color: transparent !important;
    border-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

// general
body {
    font-family: 'Open Sans', sans-serif !important;
    font-size: calc(14px + (19 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
}

#main {
    //width: calc(100% - 247px);
    max-width: 1600px;
    height: auto;
    min-height: calc(100vh - 60px);
    margin: 0 auto;
    padding: 10px 15px;
    //padding: 24px 32px;
    transition: 0.5s;
    overflow: hidden;
}

.content-wrapper {
    margin: 0 15px !important;
}

.landing-content-wrapper {
    max-width: 1600px;
    margin: 0 auto !important;
    padding: 2px 20px;
}

// headings
h1, h2 {
    color: #1f587e;
    font-family: 'Bebas Neue', cursive !important;
}

h3, h4, h5 {
    color: #1f587e;
    font-family: 'Open Sans', sans-serif !important;
}

h1 {
    font-size: calc(35px + (60 - 35) * ((100vw - 300px) / (1600 - 300))) !important;
}

h2 {
    font-size: calc(30px + (40 - 30) * ((100vw - 300px) / (1600 - 300)));
}

h3 {
    font-size: calc(17px + (35 - 25) * ((100vw - 300px) / (1600 - 300)));
}

h4 {
    font-size: calc(17px + (25 - 20) * ((100vw - 300px) / (1600 - 300)));
}

h5 {
    font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 700;
}

// forms
input[name="password"] {
    opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    background-color: transparent !important;
    background-image: none;
}

// featured form box - signin, forgotten, change pass
.featured-form-box, .registration-box {
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.34);
    background-image: linear-gradient(to bottom, #34ade9, #1d4f74);
    padding: 24px 23px 38px 23px;

    h1 {
        font-family: Open Sans, sans-serif !important;
        font-size: 20px !important;
        font-weight: 700;
        margin-bottom: 16px !important;
        color: #fff;
    }

    p {
        font-family: Open Sans, sans-serif !important;
        font-size: 16px;
    }

    label {
        color: #fff !important;
        text-transform: none !important;
    }

    input {
        color: #fff !important;
        text-transform: none !important;
    }

    a {
        color: white;
        text-decoration: none;
    }

    p {
        color: #fff !important;
        text-transform: none !important;
    }

    .form-text {
        display: block;
        margin-top: .25rem;
        font-size: 0.9rem;
        color: #b9b9b9 !important;
    }

    .password-strength-meter {
        width: 100%;
        display: none;
    }

    .password-strength-text {
        text-align: center;
    }

    #password-strength-requirements {
        color: #fff;
        font-size: 0.85em;
        text-align: left;
        margin: 0 auto;
        max-width: 350px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 4px 9px;
        margin-bottom: 20px;
    }

    #password-strength-requirements ul > li {
        font-size: 0.7em;
    }
}

.featured-form-box {
    max-width: 336px;


}

.registration-box {
    max-width: 1090px;
}

// Event name for Scoresheet and Watch
.watch-event-name, .scoresheet-event-name {
    border: none;
    background: none;
    color: #fff;
    border-bottom: dotted 1px #1f587e;
}

.watch-event-name:hover, .scoresheet-event-name:hover,
.watch-event-name:focus, .scoresheet-event-name:focus {
    outline: none;
    border-bottom: dotted 1px #0ed8ae;
}

// Game clock for Scoresheet and Watch
.game-time-input-min, .game-time-input-sec {
    border: none;
    background: none;
    width: 30px;
}

.game-time-input-min:hover, .game-time-input-sec:hover {
    border-bottom: dotted 1px #000;
}

.game-time-input-min {
    text-align: right;
}

.game-time-input-sec {
    text-align: left;
}

// Shared Watch/Scoresheet styles
.whole-table {
    .table {
        margin-bottom: 0;
    }
}

.watch-timelinetabs .event-scoresheet-times {
    position: absolute;
    width: 500px;
    right: 0;
    font-size: 1.3em !important;
}

.watch-timelinetabs .event-scoresheet-times .scoresheet-clock {
    display: none;
}

.watch-timelinetabs .event-scoresheet-times .countdown-clock > div {
    display: table-cell;
}

.watch-timelinetabs .event-scoresheet-times .scoresheet-clock {
    font-family: OpenSans, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 15px;
    left: 100px;
}

.whole-table {
    width: 100%;
    border-left: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
}

.part-table {
    width: 65%;
}

.whole-table, .part-table, .tag-property {
    /*height: calc(100vh * 0.27);*/
    /*height: calc(100vh * 0.36);*/
    /*max-height: calc(100vh * 0.30);*/
    overflow: auto;
    position: relative;
}

.table-striped tbody tr {
    background-color: rgba(0, 0, 0, 0.06);
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(31, 88, 126, 0.15);
}

.part-table tbody tr.active {
    background-color: rgba(0, 0, 0, 0.25);
}

.tag-timeline {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #373737;
    margin-left: 20px;
}

.timeline-pill {
    position: absolute;
    height: 25px;
    cursor: pointer;
    padding: 0 5px;
    background: rgba(71, 139, 196, 0.7);
    border-radius: .25rem;
    margin-left: -11px;
}

.timeline-pill-active {
    background: rgba(14, 216, 174, 0.7);
}

.info-form {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.t_header {
    text-align: center;
}

.t_body {
    text-align: center;
}

.timeColor {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #2faeea;
}

.fontColor {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #373737;
}

.tag-property {
    width: 55%;
    //height: 100%;
    min-height: 250px;
    margin-left: 20px;
    background-color: #dee6ec;
    position: sticky;
    z-index: 20;

    .header-title {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.89;
        letter-spacing: normal;
        color: #1f587e;
        margin-left: 10px;
    }

    .trash {
        cursor: pointer;
    }
}

.tag-property-header {
    border-bottom: 1px solid #c8c9cc;
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.timeline-table {
    border-top: none !important;
}

.timeline-table .timeline-mark {
    width: 10px;
    border-right: 1px solid rgba(0, 0, 0, .5);
    height: 20px;
    margin: 0;
    padding: 0;
    float: left;
}

.timeline-table .timeline-mark-first {
    width: 1px;
    margin-left: 0;
    border: none !important;
}

.timeline-top-back {
    position: fixed;
    background-color: hsla(0, 0%, 100%, .9);
    border: none;
    border-left: 1px solid #dee2e6;
    height: 68px;
    width: 100%;
    z-index: 2;
    display: none;
}

.timeline-table .timeline-mark-15 {
    border-right: 2px solid rgba(0, 0, 0, .5) !important;
    height: 25px !important;
}

.timeline-table .timeline-mark-60 {
    border-right: 2px solid rgb(0, 0, 0) !important;
    height: 30px !important;
}

.timeline-table .timeline-mark-half {
    border-right: 1px solid rgba(255, 0, 0, .5);
    height: 10px;
    padding-left: 4px;
    float: left;
}

.timeline-table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    vertical-align: top !important;
    z-index: 2;
}

.timeline-table th[scope=row] {
    position: -webkit-sticky;
    position: sticky;
    border: none;
    background-color: rgb(255, 255, 255);
    left: 0;
    //max-width: 25px;
    z-index: 1;
}

.timeline-table th.tag-name {
    z-index: 5;
}

.timeline-data form > .timeline-data-header .btn-left {
    float: left;
    border-radius: 0 0 .25rem 0;
    margin-top: -1px;
    margin-left: -1px;
}

.timeline-data form > .timeline-data-header .btn-right {
    float: right;
    border-radius: 0 0 0 .25rem;
    margin-top: -1px;
    margin-right: -1px;
}

.frmGroupTime input {
    border: none;
    text-align: center;
    border-bottom: 1px dotted;
    margin: auto 8px;
}

.timeline-tracker {
    position: absolute;
    top: 4px;
    bottom: 16px;
    left: 115px;
    width: 1px;
    background-color: red;
}

.btn-change-view {
    position: relative;
    right: -5px;
    top: -4px;
    margin-bottom: -2px;
    border-radius: 0 0 0 .25rem;
    border-top: 0;
    border-right: 0;
}

.timeline-time-display {
    float: left;
    margin: 5px;
    font-weight: 500;
}

.resizable {
    background: white;
    /*width: 100px;
    height: 100px;
    position: absolute;*/
    top: 100px;
    left: 100px;
}

.resizable .resizers {
    width: 100%;
    height: 100%;
    /*border: 3px solid #4286f4;*/
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}

.resizable .resizers:hover {
    border: 3px solid #4286f4;
}

.resizable .resizers .resizer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    border: 3px solid #4286f4;
    position: absolute;
}

.resizable .resizers .resizer.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.video-info-col h1 {
    font-size: 1.5vw;
}

.tag-property-header button {
    position: relative;
}

.tag-property-header button,
.tag-property-header button:hover,
.tag-property-header button:active,
.tag-property-header button:visited {
    border: 0 !important;
    outline: none;
}

.tag-property-header button::before,
.tag-property-header button::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

.tag-property .spin {
    line-height: 0;
    margin-right: 5px !important;
}

.tag-property-header .spin::before,
.tag-property-header .spin::after {
    top: 0;
    left: 0;
}

.tag-property-header .spin::before {
    border: 2px solid transparent;
}

.tag-property-header .spin:hover {
    color: #fff;
}

.tag-property-header .spin:hover::before {
    transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s;
}

.tag-property .spin-save {
    color: #efefef !important;
    background: rgb(106, 93, 95);
    font-size: 15px;
    height: 25px;
    width: 25px;
    top: -1px;
}

.tag-property .spin-delete {
    color: rgb(106, 93, 95) !important;
    background: transparent;
    font-size: 22px;
    width: 30px;
    height: 30px;
    top: -4px;
}

.tag-property-header .spin-save:hover::before {
    border-top-color: rgb(14, 216, 174);
    border-right-color: rgb(14, 216, 174);
    border-bottom-color: rgb(14, 216, 174);
}

.tag-property-header .spin-delete:hover::before {
    border-top-color: rgb(216, 2, 0);
    border-right-color: rgb(216, 2, 0);
    border-bottom-color: rgb(216, 2, 0);
}

.tag-property-header .spin::after {
    border: 0 solid transparent;
    border-left-width: 2px;
    border-right-width: 2px;
    transform: rotate(270deg);
    transition: transform 0.4s linear 0s, border-left-width 0s linear 0.35s, -webkit-transform 0.4s linear 0s;
}

.tag-property-header .spin-save:hover::after {
    border-top: 2px solid rgb(14, 216, 174)
}

.tag-property-header .spin-delete:hover::after {
    border-top: 2px solid rgb(216, 2, 0)
}

.tag-property-header .circle {
    border-radius: 100%;
    box-shadow: none;
}

.tag-property-header .circle::before,
.tag-property-header .circle::after {
    border-radius: 100%;
}

@media only screen
and (max-device-width: 1200px)
and (-webkit-min-device-pixel-ratio: 1) {
    .video-info-col h1 {
        font-size: 1.9vw;
    }
}

@media only screen
and (max-device-width: 768px)
and (-webkit-min-device-pixel-ratio: 1) {
    .video-info-col h1 {
        font-size: 3vw;
    }
}

/*.timeline, .timeline-data {*/
/*    overflow: auto;*/
/*    max-height: 30vh;*/
/*    position: relative;*/
/*}*/

/*.timeline-data {*/
/*    overflow-x: hidden;*/
/*}*/

/*.timeline-data form > .timeline-data-header {*/
/*    position: sticky;*/
/*    width: 100%;*/
/*    top: 0;*/
/*    background: rgb(255, 255, 255);*/
/*    width: 100%;*/
/*}*/

.timeline-table {
    border-top: none !important;
}

.timeline-table .timeline-mark {
    width: 10px;
    border-right: 1px solid rgba(0, 0, 0, .5);
    height: 20px;
    margin: 0;
    padding: 0;
    float: left;
}

.timeline-table .timeline-mark-first {
    width: 1px;
    margin-left: 0;
    border: none !important;
}

.timeline-top-back {
    position: fixed;
    background-color: hsla(0, 0%, 100%, .9);
    border: none;
    border-left: 1px solid #dee2e6;
    height: 68px;
    width: 100%;
    z-index: 2;
    display: none;
}

.timeline-table .timeline-mark-15 {
    border-right: 2px solid rgba(0, 0, 0, .5) !important;
    height: 25px !important;
}

.timeline-table .timeline-mark-60 {
    border-right: 2px solid rgb(0, 0, 0) !important;
    height: 30px !important;
}

.timeline-table .timeline-mark-half {
    border-right: 1px solid rgba(255, 0, 0, .5);
    height: 10px;
    padding-left: 4px;
    float: left;
}

.timeline-table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    vertical-align: top !important;
    z-index: 2;
}

.timeline-table th.tag-name {
    z-index: 5;
}

.timeline-data form > .timeline-data-header .btn-left {
    float: left;
    border-radius: 0 0 .25rem 0;
    margin-top: -1px;
    margin-left: -1px;
}

.timeline-data form > .timeline-data-header .btn-right {
    float: right;
    border-radius: 0 0 0 .25rem;
    margin-top: -1px;
    margin-right: -1px;
}

.frmGroupTime input {
    border: none;
    text-align: center;
    border-bottom: 1px dotted;
    margin: auto 8px;
}

.timeline-tracker {
    position: absolute;
    top: 4px;
    bottom: 16px;
    left: 115px;
    width: 1px;
    background-color: red;
}

.btn-change-view {
    position: relative;
    right: -5px;
    top: -4px;
    margin-bottom: -2px;
    border-radius: 0 0 0 .25rem;
    border-top: 0;
    border-right: 0;
}

.timeline-time-display {
    float: left;
    margin: 5px;
    font-weight: 500;
}

.resizable {
    background: white;
    /*width: 100px;
    height: 100px;
    position: absolute;*/
    top: 100px;
    left: 100px;
}

.resizable .resizers {
    width: 100%;
    height: 100%;
    /*border: 3px solid #4286f4;*/
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}

.resizable .resizers:hover {
    border: 3px solid #4286f4;
}

.resizable .resizers .resizer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    border: 3px solid #4286f4;
    position: absolute;
}

.resizable .resizers .resizer.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 834px)
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 834px)
and (max-device-width: 834px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1112px)
and (max-device-width: 1112px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1024px)
and (max-device-width: 1024px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1366px)
and (max-device-width: 1366px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {

}

.t_header {
    text-align: center;
}

.t_body {
    text-align: center;
}

.timeColor {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #2faeea;
}

.fontColor {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #373737;
}

.trash {
    cursor: pointer;
}

.tag-property {
    width: 55%;
    height: 100%;
    margin-left: 20px;
    background-color: #dee6ec;
    position: sticky;
    z-index: 20;

    .submit {
        font-size: 12px;
        align-items: center;
        margin-top: 10px;
    }
}

.tag-property-header {
    border-bottom: 1px solid #c8c9cc;
    height: 40px;
    display: flex;
    justify-content: space-between;
}

.header-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.89;
    letter-spacing: normal;
    color: #1f587e;
    margin-left: 10px;
}

/*.form-group {*/
/*    margin-bottom: 0;*/
/*}*/
.tag-timeline {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    color: #373737;
    margin-left: 20px;
}

.timeline-pill {
    position: absolute;
    height: 25px;
    cursor: pointer;
    padding: 0 5px;
    background: rgba(71, 139, 196, 0.7);
    border-radius: .25rem;
    margin-left: -11px;
}

.timeline-pill-active {
    background: rgba(14, 216, 174, 0.7);
}

.info-form {
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.whole-table {
    width: 100%;
    border-left: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
}

.part-table {
    width: 45%;
}

.whole-table, .part-table, .tag-property {
    /*height: calc(100vh * 0.27);*/
    height: calc(100vh * 0.36);
    /*max-height: calc(100vh * 0.30);*/
    overflow: auto;
    position: relative;
    /*height: calc(25vh - 30px);*/
}

.part-table .col-team, .part-table .col-player, .part-table .col-notes, .part-table .col-remove {
    display: none;
}

.table-striped tbody tr {
    background-color: rgba(0, 0, 0, 0.06);
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(31, 88, 126, 0.15);
}

.part-table tbody tr.active {
    background-color: rgba(0, 0, 0, 0.25);
}

.table-striped tbody tr:active {
    font-weight: 600;
}


@media only screen and (max-width: 767px) {
    .watch-timelinetabs .nav-tabs {
        display: none;
    }

    .whole_table, .part_table, .tag-property {
        height: calc(100vh * 0.4) !important;
    }

    .part_table {
        width: 100% !important;
    }

    .whole_table table th:nth-of-type(3),
    .whole_table table th:nth-of-type(4),
    .whole_table table th:nth-of-type(5),
    .whole_table table th:nth-of-type(6),
    .whole_table table td:nth-of-type(3),
    .whole_table table td:nth-of-type(4),
    .whole_table table td:nth-of-type(5),
    .whole_table table td:nth-of-type(6) {
        display: none;
    }

    .tag-property {
        position: absolute !important;
        width: 100vw !important;
        z-index: 9999 !important;
        left: -5px !important;
    }

    #tabGameInfoTags-tabpane-gameInfo {
        padding: 0 8px;
    }

    .video-content-row, .watch-timelinetabs {
        /*padding-left: 20px; */
        /*padding-right: 20px;*/
    }

    .timeline-time-display {
        display: none;
    }

    .videoStage {
        /*width: 100vw;*/
        /*height: 332px !important;*/
        /*padding: 0;*/
        margin: 0 auto;
        /*margin-top: -63px;*/
    }

    .videoStage .video-js {
        /*width: 100vw;*/
        /*height: 360px !important;*/
        /*padding-bottom: 64px;*/
    }

    .videoStage, #watcher-container .video-js {
        /*height: 360px !important;*/
        height: fit-content !important;
        width: 100vw;
    }

    .watch-timelinetabs {
        /*margin-left: -31px;*/
        /*margin-right: -18px;*/
    }

    .rightGameInfoTags {
        width: 100vw;
        margin: 0 auto;
        padding: 0;
        max-height: 60vh;
    }

    .game-title {
        display: none;
    }

    .game-title-mobile {
        display: block;
        margin-bottom: 0;
        margin-top: -5px;
        font-weight: 600;
    }

    #tabGameInfoTags-tabpane-gameInfo select {
        display: none;
    }

    #tabGameInfoTags-tabpane-tags {
        text-align: center;
    }

    #tabGameInfoTags-tabpane-tags .tag-button {
        width: auto;
        margin: 3px;
        padding: 0 3px;
    }

    #tabGameInfoTags-tabpane-tags .tag-button-count {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .video-content-row {
        margin: 0 auto;
        width: 100vw;
        padding: 0;
    }

    .watch-timelinetabs {
        /*margin-left: -40px;*/
    }
}
