@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import 'colors';

header {
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 60;

    .top-line {
        background-color: $top-line-bg;
        height: 60px;

        .top-login {
            display: none !important;
        }

        a {
            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $top-line-color;
            margin-left: 0;
            text-decoration: none;
        }

        #topbarNav {
            a {
                color: $top-line-color;
                text-transform: uppercase;

                &:hover {
                    color: #7bbbda;
                }
            }
        }

        .left-menu-toggle {
            font-size: 25px;
            margin: 0 11px;
            color: #ffffff;
            cursor: pointer;
        }

        .logo {
            margin-right: 7px;
            margin-left: 20px;
            height: 45px;
            width: auto;
        }

        .logo-text {
            height: 24px;
            font-size: 18px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: $top-line-color;
        }
    }

    @include media-breakpoint-down(md) {
        .top-line {
            .navbar {
                position: absolute;
                right: 16px;
                top: 0;
            }
        }
        //#topbarNav {
        //    margin-top: 47px;
        //    padding: 15px;
        //    position: relative;
        //    right: -20px;
        //    width: 105vw;
        //    height: 70px;
        //    max-height: 70px;
        //}
        .top-line {
            .nav-link {
                padding: 20px 5px;
            }

            .top-signout {
                margin: 9px 20px;
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .featured-box {
        padding: 10px 16px;
        margin: 0;
    }
    .our-tech-title {
        h2 {
            width: 100%;
            text-align: center;
        }
    }
}

@include media-breakpoint-up(lg) {
    //#topbarNav {
    //    position: inherit;
    //    width: inherit;
    //}
    .has-search {
        margin-right: 70px !important;
        margin-left: 50px;
    }
}

